import React from "react";
import Logo from "../../../assets/images/logo_emploi.png";
import style from "./style";
import Facebook from "../../../assets/images/facebook-logo.png";
import LinkedIn from "../../../assets/images/linkedin-letters.png";
import Twitter from "../../../assets/images/twitter.png";

const links = [
  {
    link: "https://www.facebook.com/MaisonEmploiStrasbourg/",
    image: Facebook
  },
  {
    link: "https://fr.linkedin.com/company/maison-de-l’emploi-de-strasbourg",
    image: LinkedIn
  },
  {
    link: "https://twitter.com/mde_strasbourg/",
    image: Twitter
  }
];

const SignatureEmploi = ({ index, poste, numeros }) => {
  return (
    <>
      <tr>
        <td style={{ verticalAlign: "middle", width: "150px" }}>
          <img
            alt="La maison de l'emploi"
            src={Logo}
            style={{ maxWidth: "150px" }}
          />
        </td>
        <td style={{ verticalAlign: "top", paddingLeft: "30px" }}>
          <p style={style.detailsTitre}>
            LA MAISON DE L'EMPLOI
            <br />
            <span style={style.detailsStyle}>
              {index === 0 && (
                <>
                  {poste}
                  <br />
                </>
              )}
              {numeros
                ? numeros.map((numero, index) => (
                    <a
                      style={style.linkStyle}
                      href={`tel:${numero}`}
                      key={index}
                    >
                      {numero}
                      <br />
                    </a>
                  ))
                : null}
              <a
                style={style.linkStyle}
                href="https://www.maisonemploi-strasbourg.org"
                target="blank"
                rel="nofollow noopener"
              >
                www.maisonemploi-strasbourg.org
              </a>
            </span>
          </p>
          <ul style={style.ulStyle}>
            {links.map((link, index) => {
              return (
                <li style={style.listStyle} key={index}>
                  <a style={style.linkListStyle} href={link.link}>
                    <img
                      style={{ height: "100%" }}
                      src={link.image}
                      alt="link"
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px" }}></td>
      </tr>
    </>
  );
};

export default SignatureEmploi;
