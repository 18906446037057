import React, { Component } from "react";
import { base } from "../../base";
import SignatureKaleidoscoop from "./SignatureInfos/SignatureKaleidoscope";
import SignatureEmploi from "./SignatureInfos/SignatureEmploi";
import SignatureCoop from "./SignatureInfos/SignatureCoop";
import SignatureGrandEst from "./SignatureInfos/SignatureGrandEst";

const detailsTitre = {
  fontFamily: "Helvetica, Arial, sans-serif",
  fontSize: "16px",
  color: "black",
  lineHeight: "1.5",
  fontWeight: "600",
  textTransform: "uppercase"
};

export default class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id_signature,
      name: "",
      lastName: "",
      poste: "",
      entities: []
    };

    const data = base.collection("emails").doc(`${this.state.id}`);

    data.get().then(doc => {
      const fullData = doc.data();
      const stateCopy = { ...this.state };

      stateCopy.name = fullData.name;
      stateCopy.lastName = fullData.lastName;
      stateCopy.poste = fullData.poste;
      stateCopy.entities = fullData.selectedEntities;
      stateCopy.email = fullData.email;

      this.setState(stateCopy);
    });
  }
  render() {
    const fullName = this.state.name + " " + this.state.lastName;

    return (
      <table
        style={{
          width: "100%",
          maxWidth: "500px",
          margin: 0,
          padding: "20px",
          fontFamily: "Arial, sans-serif",
          fontSize: "12px",
          display: "block",
          boxSizing: "border-box"
        }}
        width="500"
        cellPadding="0"
        cellSpacing="0"
        border="0"
        align="center"
      >
        <tbody>
          <tr>
            <td colSpan="100%" style={{ paddingBottom: "30px" }}>
              <p
                style={{
                  ...detailsTitre,
                  fontSize: "24px",
                  textTransform: "capitalize",
                  lineHeight: "1.15"
                }}
              >
                {fullName}
              </p>
            </td>
          </tr>
          {this.state.entities.map((entity, index) => {
            switch (entity.name) {
              case "kaleidoscoop":
                return (
                  <SignatureKaleidoscoop
                    key={index}
                    index={index}
                    email={entity.email}
                    numeros={entity.numeros}
                    poste={this.state.poste}
                  />
                );

              case "maison-de-lemploi":
                return (
                  <SignatureEmploi
                    key={index}
                    index={index}
                    email={entity.email}
                    numeros={entity.numeros}
                    poste={this.state.poste}
                  />
                );

              case "cooproduction":
                return (
                  <SignatureCoop
                    key={index}
                    index={index}
                    email={entity.email}
                    numeros={entity.numeros}
                    poste={this.state.poste}
                  />
                );

              case "grandest":
                return (
                  <SignatureGrandEst
                    key={index}
                    index={index}
                    email={entity.email}
                    numeros={entity.numeros}
                    poste={this.state.poste}
                  />
                );

              default:
                throw new Error("L'entité n'existe pas.");
            }
          })}
        </tbody>
      </table>
    );
  }
}
