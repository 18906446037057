const style = {
  detailsTitre: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontSize: "16px",
    color: "black",
    lineHeight: "1.5",
    fontWeight: "600",
    textTransform: "uppercase"
  },

  detailsStyle: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontSize: "16px",
    color: "black",
    textDecoration: "none",
    fontWeight: "350",
    textTransform: "initial"
  },

  linkStyle: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontSize: "16px",
    color: "black",
    textDecoration: "none",
    fontWeight: "350",
    textTransform: "initial"
  },

  listStyle: {
    display: "inline-block",
    marginRight: "15px"
  },

  linkListStyle: {
    display: "block",
    height: "22px",
    width: "auto"
  },

  ulStyle: {
    marginTop: "12px"
  }
};

export default style;
