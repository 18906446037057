import React from "react";
import Logo from "../../../assets/images/logo_cooproduction.jpg";
import Facebook from "../../../assets/images/facebook-logo.png";
import Instagram from "../../../assets/images/instagram-logo.png";
import LinkedIn from "../../../assets/images/linkedin-letters.png";
import Twitter from "../../../assets/images/twitter.png";
import style from "./style";

const links = [
  {
    link: "https://fr.linkedin.com/company/cress-grand-est",
    image: Twitter
  }
];

const SignatureCoop = ({ index, poste, numeros }) => {
  return (
    <>
      <tr>
        <td style={{ verticalAlign: "middle", width: "150px" }}>
          <img
            alt="La maison de l'emploi"
            src={Logo}
            style={{ maxWidth: "150px" }}
          />
        </td>
        <td
          style={{
            verticalAlign: "top",
            paddingLeft: "30px",
            paddingTop: "10px"
          }}
        >
          <p style={style.detailsTitre}>
            COOPRODUCTION
            <br />
            <span style={style.detailsStyle}>
              {index === 0 && (
                <>
                  {poste}
                  <br />
                </>
              )}
              {numeros
                ? numeros.map((numero, index) => (
                    <a
                      style={style.linkStyle}
                      href={`tel:${numero}`}
                      key={index}
                    >
                      {numero}
                      <br />
                    </a>
                  ))
                : null}
              <a
                style={style.linkStyle}
                href="http://cooproduction.coop/"
                target="blank"
                rel="nofollow noopener"
              >
                cooproduction.coop
              </a>
            </span>
          </p>
          <ul style={style.ulStyle}>
            {links.map((link, index) => {
              return (
                <li style={style.listStyle} key={index}>
                  <a style={style.linkListStyle} href={link.link}>
                    <img
                      style={{ height: "100%" }}
                      src={link.image}
                      alt="link"
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </td>
      </tr>
      <tr>
        <td style={{ padding: "10px" }}></td>
      </tr>
    </>
  );
};

export default SignatureCoop;
